import React from 'react'
import Layout from '../components/layout'

export default () => (
    <Layout pageTitle="Privacy Policy" metaDescription="Privacy Policy" metaKeywords={['privacy policy', 'privacy']}>
        <div id="main">
            <div className="inner">
                <h1 id="content">Privacy Policy & Cookies</h1>
                                
                <h2>Privacy Policy</h2>

                <p>We are committed to safeguarding the privacy of our website
                visitors and clients. This privacy policy will explain how White Bear
                Digital use the personal data we collect from you when you use our
                website. This policy applies to our website and our responsibilities
                as a data controller with the data our website collects. This policy
                statement does not cover our responsibilities as a data processor
                for the data we process for our clients, which are covered in a
                separate agreement with our clients.</p>

                <p>Topics:</p>
                <ol>
                    <li>Compliance and Security</li>
                    <li>What data do we collect?</li>
                    <li>How do we collect your data?</li>
                    <li>How will we use your data?</li>
                    <li>How do we store your data?</li>
                    <li>Marketing</li>
                    <li>What are your data protection rights?</li>
                    <li>What are cookies?</li>
                    <li>How do we use cookies?</li>
                    <li>What types of cookies do we use?</li>
                    <li>How to manage your cookies</li>
                    <li>Privacy policies of other websites</li>
                    <li>Changes to our privacy policy</li>
                    <li>How to contact us</li>
                    <li>How to contact the appropriate authorities</li>
                </ol>

                <h3>Compliance and Security</h3>

                <p>White Bear Digital holds the UK Cyber Essentials qualification,
                Certificate no: 0706583859955686</p>
                <p>White Bear Digital is registered with the UK Information
                Commissioner’s Office.</p>

                <h3>What data do we collect?</h3>

                <p>The White Bear Digital website collects the following data:</p>
                <p>Personal identification information (Name, email address,
                message data)</p>
 
                <h3>How do we collect your data?</h3>

                <p>You directly provide White Bear Digital with most of the data we
                collect. We collect data and process data when you:</p>
                <ol>
                <li>Fill out our contact form.</li>
                <li>Subscribe to our newsletter.</li>
                <li>Use or view our website via your browser’s cookies.
                White Bear Digital may also receive your data indirectly from the
                following sources:</li>
                <li>Social media posts that you make in association with our
                social media profiles.</li>
                <li>Newsletter signup and usage.</li>
                </ol>


                <h3>How will we use your data?</h3>
                <p>White Bear Digital collects your data so that we can:</p>
                <ol>
                <li>Contact you to discuss our services.</li>
                <li>Email you with special offers on other products and services
                we think you might like.</li>
                </ol>

                <h3>How do we store your data?</h3>
                <p>White Bear Digital securely stores your data at our UK office in a
                customer management database. Access to this database is
                secured by user accounts and is not exposed to the internet.
                White Bear Digital will keep your contact details for one year. Once
                this time period has expired, if it is no longer required for customer
                contact reasons we will delete your data by permanently removing it
                from our database.</p>


                <h3>Marketing</h3>
                <p>White Bear Digital would like to send you information about
                products and services of ours that we think you might like. We will
                not send information about any third party products or services.
                If you have agreed to receive marketing, you may always opt out at
                a later date.</p>
                <p>You have the right at any time to stop White Bear Digital from
                contacting you for marketing purposes.</p>
                <p>If you no longer wish to be contacted for marketing purposes,
                please contact us to have your details removed.</p>

                <h3>What are your data protection rights?</h3>
                <p>White Bear Digital would like to make sure you are fully aware of all
                of your data protection rights. Every user is entitled to the following:
                The right to access – You have the right to request White Bear
                Digital for copies of your personal data. We may charge you a small
                fee for this service.</p>
                <p>The right to rectification – You have the right to request that White
                Bear Digital correct any information you believe is inaccurate. You
                also have the right to request White Bear Digital to complete the
                information you believe is incomplete.</p>
                <p>The right to erasure – You have the right to request that White
                Bear Digital erase your personal data, under certain conditions.</p>
                <p>The right to restrict processing – You have the right to request
                that White Bear Digital restrict the processing of your personal data,
                under certain conditions.</p>
                <p>The right to object to processing – You have the right to object to
                White Bear Digital’s processing of your personal data, under certain
                conditions.</p>
                <p>The right to data portability – You have the right to request that
                White Bear Digital transfer the data that we have collected to
                another organisation, or directly to you, under certain conditions.</p>
                <p>If you make a request, we have one month to respond to you. If you
                would like to exercise any of these rights, please contact us:</p>
                <ol>
                    <li>Email us at: hello@wearewhitebear.com</li>
                    <li>Call us on: 01902 239077</li>
                    <li><p>
                        Or write to us:<br/>
                        98 Grosvenor Road<br/>
                        Ettingshall Park<br/>
                        Wolverhampton<br/>
                        England<br/>
                        WV4 6QT
                    </p></li>
                </ol>

                <h2>Cookies</h2>

                <p>Cookies are text files placed on your computer to collect standard
                internet log information and visitor behaviour information. When you
                visit our websites, we may collect information from you
                automatically through cookies or similar technology. For further information, visit <a target="blank" href="https://allaboutcookies.org">https://allaboutcookies.org</a>.</p>


                <h3>How do we use cookies?</h3>

                <p>White Bear Digital uses cookies in a range of ways to improve your
                experience on our website, including:</p>
                <ol>
                <li>Understanding how you use our website</li>
                <li>Email marketing signup process</li>
                <li>Protecting our contact forms from spam</li>
                </ol>



                <h3>What types of cookies do we use?</h3>
                <p>There are a number of different types of cookies, however, our
                website uses:</p>
                <ol>
                <li>Functionality – White Bear Digital uses these cookies so that
                we recognise you on our website and remember your
                previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
                <li>Analytics – White Bear Digital uses these cookies to collect
                information about your visit to our website, the content you
                viewed, the links you followed and information about your
                browser, device, and your IP address.</li>
                <li>Protection - White Bear Digital uses these cookies to protect
                our contact forms from spam.</li>
                </ol>

                <h3>How to manage cookies</h3>
                <p>You can set your browser not to accept cookies, and the above
                website tells you how to remove cookies from your browser.
                However, in a few cases, some of our website features may not
                function as a result.</p>


                <h3>Privacy policies of other websites</h3>
                <p>The White Bear Digital website may contain links to other websites.
                Our privacy policy applies only to our website, so if you click on a
                link to another website, you should read their privacy policy.</p>


                <h3>Changes to our privacy policy</h3>
                <p>White Bear Digital keeps its privacy policy under regular review and
                places any updates on this web page. This privacy policy was last
                updated on 21st May 2020.</p>
                <h3>How to contact us</h3>

                <p>If you have any questions about White Bear Digital’s privacy policy,
                the data we hold on you, or you would like to exercise one of your
                data protection rights, please do not hesitate to contact us:</p>
                <ol>
                    <li>Email us at: hello@wearewhitebear.com</li>
                    <li>Call us on: 01902 239077</li>
                    <li><p>
                        Or write to us:<br/>
                        98 Grosvenor Road<br/>
                        Ettingshall Park<br/>
                        Wolverhampton<br/>
                        England<br/>
                        WV4 6QT
                    </p></li>
                </ol>


                <h3>How to contact the appropriate authority</h3>
                <p>Should you wish to report a complaint or if you feel that White Bear
                Digital has not addressed your concern in a satisfactory manner,
                you may contact the UK Information Commissioner’s Office via their
                website at <a target="blank" href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a></p> 

            </div>
        </div>
    </Layout>
)
